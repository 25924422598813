import * as types from './types'
import { HTTP_BACKEND_URL } from '../const'

const ACCESS_TOKEN = 'ACCESS_TOKEN'

const defaultCatch = dispatch => async err => {
    try {
        const body = await err.json()
        if ( body && body.error === ACCESS_TOKEN ) {
            dispatch(signOut())
            dispatch(setSnackbar('Invalid access token. Please sign in.', 'error'))
        } else {
            dispatch(setSnackbar('Error.', 'error'))
        }
    } catch ( e ) {
        dispatch(setSnackbar('Error.', 'error'))
    }
}

export const setUser = user => 
    ({
        type: types.SET_USER,
        payload: user
    })

export const signIn = (email, password) =>
    dispatch => {
        const opts = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email, password})
        };
        fetch(`${HTTP_BACKEND_URL}/signin`, opts)
            .then(res => {
                dispatch(setHttpResponse(res, 'SIGN_IN'))
                if ( res.ok ) return res.json()
                else {
                    if ( [400, 401].includes(res.status) ) {
                        dispatch(setSnackbar('Invalid credentials.', 'error'))
                        res.suppressNotification = true 
                    }
                    return Promise.reject(res)
                }
            }).then(user => {
                dispatch(setUser(user))
            }).catch(err => {
                console.error(err)
                if ( !err.suppressNotification ) {
                    dispatch(setSnackbar('Error.', 'error'))
                }
            })
    }

export const signOut = () => 
    ({
        type: types.SIGN_OUT,
        payload: null
    })

export const disconnectStripe = user => 
    dispatch => {
        const opts = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        };
        fetch(`${HTTP_BACKEND_URL}/user/${user.id}/disconnect-stripe`, opts)
            .then(res => {
                if ( res.ok ) {
                    dispatch(setUserStripe(null))
                    dispatch(getLinks(user))
                } else return Promise.reject(res)
            }).catch(defaultCatch(dispatch))
    }

export const updateStripeAccount = user => 
    dispatch => {
        const opts = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        };
        fetch(`${HTTP_BACKEND_URL}/user/${user.id}/connect-stripe/callback`, opts)
            .then(res => {
                if ( res.status === 200 ) {                    
                    return res.json()
                } else if ( res.status === 204 ) {
                    dispatch(setUserStripe(null))
                } else {
                    return Promise.reject(res)
                }
            }).then(res => {
                dispatch(setUserStripe(res))
            }).catch(defaultCatch(dispatch))
    }

export const getUser = user => 
    dispatch => {
        const opts = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        };
        fetch(`${HTTP_BACKEND_URL}/user/${user.id}`, opts)
            .then(res => {
                if ( res.status === 200 ) {                    
                    return res.json()
                } else return Promise.reject(res)
            }).then(res => {
                dispatch(setUser({
                    ...res, 
                    token: user.token
                }))
            }).catch(defaultCatch(dispatch))
    }

export const createAccount = (email, password) =>
    dispatch => {
        const opts = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email, password})
        };
        fetch(`${HTTP_BACKEND_URL}/user`, opts)
            .then(res => {
                dispatch(setHttpResponse(res, 'CREATE_ACCOUNT'))
                if ( res.ok ) return res.json()
                return Promise.reject(res)
            }).then(user => {
                dispatch({
                    type: types.SET_USER,
                    payload: user
                })
                dispatch(setSnackbar('A new account was created. Continue by connecting with Stripe.', 'success', 10000))
            }).catch(err => {
                console.error(err)
                dispatch(setSnackbar('Error. An account for the email address may already exist.', 'error'))
            })
    }

export const patchAccount = (user, email, password) =>
    dispatch => {
        const opts = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({email, password})
        };
        fetch(`${HTTP_BACKEND_URL}/user/${user.id}`, opts)
            .then(res => {
                dispatch(setHttpResponse(res, 'PATCH_ACCOUNT'))
                if ( res.ok ) return res.json()
                return Promise.reject(res)
            }).then(user => {
                dispatch({
                    type: types.SET_USER,
                    payload: user
                })
                dispatch(setSnackbar('Account was updated.'))
            }).catch(defaultCatch(dispatch))
    }

export const removeAccount = user =>
    dispatch => {
        const opts = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        };
        fetch(`${HTTP_BACKEND_URL}/user/${user.id}`, opts)
            .then(res => {
                dispatch(setHttpResponse(res, 'REMOVE_ACCOUNT'))
                if ( res.ok ) dispatch(signOut())
                else return Promise.reject(res)
            }).catch(defaultCatch(dispatch))
    }

export const getLinks = user =>
    dispatch => {
        if ( !user ) return
        const opts = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }
        fetch(`${HTTP_BACKEND_URL}/user/${user.id}/link`, opts)
            .then(res => {
                dispatch(setHttpResponse(res, 'GET_LINKS'))
                if ( res.ok ) return res.json()
                return Promise.reject(res)
            }).then(res => {
                dispatch({
                    type: types.SET_LINKS,
                    payload: res.links
                })
            }).catch(defaultCatch(dispatch))
    }

export const createLink = (user, link) =>
    dispatch => {
        if ( !user ) return
        const opts = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }, body: JSON.stringify(link)
        }
        fetch(`${HTTP_BACKEND_URL}/link`, opts)
            .then(res => {
                dispatch(setHttpResponse(res))
                if ( res.ok ) return res.json()
                return Promise.reject(res)
            }).then(link => {
                dispatch({
                    type: types.ADD_LINK,
                    payload: link
                })
                dispatch(setSnackbar('A new link was created.'))
            }).catch(defaultCatch(dispatch))
    }

export const patchLink = (user, link, patch) =>
    dispatch => {
        if ( !user ) return
        const opts = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }, body: JSON.stringify(patch)
        }
        fetch(`${HTTP_BACKEND_URL}/link/${link.id}${patch.key ? '/' + patch.key : ''}`, opts)
            .then(res => {
                const actionId = 'PATCH_LINK' + (patch.key ? '_' + patch.key.toUpperCase() : '')
                dispatch(setHttpResponse(res, actionId))
                if ( res.ok ) return res.json()
                else return Promise.reject(res)
            }).then(link => {
                dispatch({
                    type: types.PATCH_LINK,
                    payload: link
                })
                if ( patch.key && patch.key === 'live' ) {
                    if ( patch.value ) {
                        dispatch(setSnackbar('The link is now live.'))
                    } else {
                        dispatch(setSnackbar('The link is now offline.'))
                    }
                } else {
                    dispatch(setSnackbar('The link was updated.'))
                }
            }).catch(err => {
                console.error(err)
                if ( err.status === 409 ) {
                    dispatch(setSnackbar('Cannot go live because the account has not been connected with Stripe.', 'error'))
                } else return defaultCatch(dispatch)(err)
            })
    }

export const removeLink = (user, link) =>
    dispatch => {
        if ( !user ) return
        const opts = {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }
        fetch(`${HTTP_BACKEND_URL}/link/${link.id}`, opts)
            .then(res => {
                dispatch(setHttpResponse(res))
                if ( res.ok ) return res.json()
                else return Promise.reject(res)
            }).then(link => {
                dispatch({
                    type: types.REMOVE_LINK,
                    payload: link
                })
                dispatch({
                    type: types.SET_SNACKBAR,
                    payload: {
                        type: 'success',
                        message: 'The link was removed.'
                    }
                })
            }).catch(defaultCatch(dispatch))
    }

export const getCollections = user =>
    dispatch => {
        if ( !user ) return
        const opts = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }
        fetch(`${HTTP_BACKEND_URL}/user/${user.id}/collection`, opts)
            .then(res => {
                dispatch(setHttpResponse(res))
                if ( res.ok ) return res.json()
                return Promise.reject(res)
            }).then(res => {
                dispatch({
                    type: types.SET_COLLECTIONS,
                    payload: res.collections
                })
            }).catch(defaultCatch(dispatch))
    }

export const createCollection = (user, collection) =>
    dispatch => {
        if ( !user ) return
        const opts = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }, body: JSON.stringify(collection)
        }
        fetch(`${HTTP_BACKEND_URL}/collection`, opts)
            .then(res => {
                dispatch(setHttpResponse(res))
                if ( res.ok ) return res.json()
                return Promise.reject(res)
            }).then(collection => {
                dispatch({
                    type: types.ADD_COLLECTION,
                    payload: collection
                })
                dispatch(setSnackbar('A new collection was created.'))
            }).catch(defaultCatch(dispatch))
    }

export const patchCollection = (user, collection, patch) =>
    dispatch => {
        if ( !user ) return
        const opts = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }, body: JSON.stringify(patch)
        }
        fetch(`${HTTP_BACKEND_URL}/collection/${collection.id}`, opts)
            .then(res => {
                dispatch(setHttpResponse(res, 'PATCH_COLLECTION'))
                if ( res.ok ) return res.json()
                else return Promise.reject(res)
            }).then(link => {
                dispatch({
                    type: types.PATCH_COLLECTION,
                    payload: link
                })
                if ( 'live' in patch ) {
                    if ( patch.live ) {
                        dispatch(setSnackbar('The collection is now live.'))
                    } else {
                        dispatch(setSnackbar('The collection is now offline.'))
                    }
                } else {
                    dispatch(setSnackbar('The collection was updated.'))
                }
            }).catch(defaultCatch(dispatch))
    }

export const removeCollection = (user, collection) =>
    dispatch => {
        if ( !user ) return
        const opts = {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }
        fetch(`${HTTP_BACKEND_URL}/collection/${collection.id}`, opts)
            .then(res => {
                dispatch(setHttpResponse(res))
                if ( res.ok ) return res.json()
                else return Promise.reject(res)
            }).then(collection => {
                dispatch({
                    type: types.REMOVE_COLLECTION,
                    payload: collection
                })
                dispatch({
                    type: types.SET_SNACKBAR,
                    payload: {
                        type: 'success',
                        message: 'The collection was removed.'
                    }
                })
            }).catch(defaultCatch(dispatch))
    }

export const forgotPassword = email =>
    dispatch => {
        const opts = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            }, body: JSON.stringify({email})
        }
        fetch(`${HTTP_BACKEND_URL}/forgot-password`, opts)
            .then(res => {
                dispatch(setHttpResponse(res, 'FORGOT_PASSWORD'))
                if ( res.ok ) {
                    dispatch(setSnackbar('A one-time login link was sent.'))
                } else {
                    return Promise.reject(res)
                }
            }).catch(err => {
                console.error(err)
                dispatch(setSnackbar('Error.', 'error'))
            })
    }

export const setSnackbarOpen = open => 
    ({
        type: types.SET_SNACKBAR_OPEN,
        payload: open
    })

export const setSnackbar = (message, type='success', duration=3000, id=null) => 
    ({
        type: types.SET_SNACKBAR,
        payload: {type, message, duration, id}
    })

export const setUserStripe = value => 
    ({
        type: types.SET_USER_STRIPE,
        payload: value
    })


export const setHttpResponse = (res, id=null) =>
    ({
        type: types.SET_HTTP_RESPONSE,
        payload: {
            url: res.url, 
            status: res.status, 
            ok: res.ok,
            id
        }
    })

export const setHandledHttpResponse = res =>
    ({
        type: types.SET_HANDLED_HTTP_RESPONSE,
        payload: res
    })

export const setConsent = value =>
    ({
        type: types.SET_CONSENT,
        payload: value
    })

export const setShare = value =>
    ({
        type: types.SET_SHARE,
        payload: value
    })

export const setConfirmModal = value => 
    ({
        type: types.SET_CONFIRM_MODAL,
        payload: value
    })

export const setConfirmModalOpen = value => 
    ({
        type: types.SET_CONFIRM_MODAL_OPEN,
        payload: value
    })