import React from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import * as actions from '../redux/actions'
import { useDispatch, useSelector } from 'react-redux'

const ShareTargetView = props => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user) 
  if ( user ) {
    const share = queryString.parse(window.location.search)
    dispatch(actions.setShare(share))
  }
  return <Redirect to="/links" />
}

export default ShareTargetView;