import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LoadingView from './views/LoadingView';
import OneTimeTokenView from './views/OneTimeTokenView';
import ShareTargetView from './views/ShareTargetView';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import reducer from "./redux/reducer";
import { PersistGate } from 'redux-persist/lib/integration/react'
import { CssBaseline } from '@mui/material'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const LoginView = lazy(() => import("./views/LoginView"));
const Layout = lazy(() => import("./views/Layout"));

const persistConfig = {
  key: 'root',
  storage
}

const pReducer = persistReducer(persistConfig, reducer)

const configureStore = initialState => 
  createStore(
    pReducer, initialState,
    compose(applyMiddleware(thunk))
  )

const store = configureStore()
const persistor = persistStore(store)

window.store = store

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
          <BrowserRouter>
            <CssBaseline />
            <Suspense fallback={<LoadingView />}>
              <Switch>
                <Route exact path="/token/:token" component={OneTimeTokenView}/>
                <Route exact path="/share-target" component={ShareTargetView}/>
                <Route exact path="/signin" component={LoginView}/>
                <Route exact path="/">{() => 
                  store.getState().user
                    ? <Redirect to={
                      store.getState().user.stripe &&
                      store.getState().user.stripe.enabled &&
                      store.getState().user.stripe.accountId
                        ? '/links'
                        : '/account'
                    } />
                    : <Redirect to="/signin" />
                }</Route>
                <Route component={Layout}/>
              </Switch>
            </Suspense>
          </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

