import { patchAccount } from './actions'
import * as types from './types'

const initialState = {
    user: null,
    links: [],
    collections: [],
    snackbar: {},
    httpResponse: null,
    handledHttpResponse: null,
    consent: false,
    share: null,
    confirmModal: {open: false}
}

const patch = (state, action, key) => {
    const i = state[key].findIndex(
        item => item.id === action.payload.id
    )
    if ( i < 0 ) return state
    else {
        const items = [...state[key]]
        const item = {...items[i], ...action.payload}
        items[i] = item
        return {...state, [key]: items}
    }
}

const remove = (state, action, key) => {
    const items = state[key].filter(
        item => item.id !== action.payload.id
    )
    return {...state, [key]: items}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIGN_OUT:
            return {...initialState, consent: state.consent}
        case types.SET_CONFIRM_MODAL_OPEN:
            return {...state, confirmModal: {...state.confirmModal, open: action.payload}}
        case types.SET_CONFIRM_MODAL:
            return {...state, confirmModal: action.payload}
        case types.SET_SHARE:
            return {...state, share: action.payload}
        case types.SET_CONSENT:
            return {...state, consent: action.payload}
        case types.SET_USER_STRIPE:
            return {...state, user: {...state.user, stripe: action.payload}}
        case types.SET_HANDLED_HTTP_RESPONSE:
            return {...state, handledHttpResponse: action.payload}
        case types.SET_HTTP_RESPONSE:
            return {...state, httpResponse: action.payload}
        case types.SET_CREDITS:
            return {...state, user: {...state.user, credits: action.payload}}
        case types.SET_SNACKBAR_OPEN:
            return {...state, snackbar: {...state.snackbar, open: action.payload}}
        case types.SET_SNACKBAR:
            return {...state, snackbar: {...action.payload, open: true}}
        case types.REMOVE_LINK:
            return remove(state, action, 'links')
        case types.PATCH_LINK:
            return patch(state, action, 'links')
        case types.ADD_LINK:
            return {...state, links: [action.payload, ...state.links]}
        case types.REMOVE_COLLECTION:
            return remove(state, action, 'collections')
        case types.PATCH_COLLECTION:
            return patch(state, action, 'collections')
        case types.ADD_COLLECTION:
            return {...state, collections: [action.payload, ...state.collections]}
        case types.SET_COLLECTIONS:
            return {...state, collections: action.payload}
        case types.SET_LINKS:
            return {...state, links: action.payload}
        case types.SET_USER:
            return {...state, user: action.payload}
        case types.SET_TOKEN:
            return {...state, user: {...state.user, ...action.payload}}
        default:
            return state
    }
}

export default reducer