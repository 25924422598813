import '../App.css';
import { CircularProgress } from '@mui/material'

const LoadingView = () => {

    return <div className="App">
        <div className="progress-overlay">
           <CircularProgress size={80} className="progress" />
        </div>
    </div>
}

export default LoadingView;