export const SET_USER = "SET_USER"
export const SET_USER_STRIPE = "SET_USER_STRIPE"
export const SET_TOKEN = "SET_TOKEN"
export const SET_COLLECTIONS = "SET_COLLECTIONS"
export const ADD_COLLECTION = "ADD_COLLECTION"
export const PATCH_COLLECTION = "PATCH_COLLECTION"
export const REMOVE_COLLECTION = "REMOVE_COLLECTION"
export const SET_LINKS = "SET_LINKS"
export const ADD_LINK = "ADD_LINK"
export const PATCH_LINK = "PATCH_LINK"
export const REMOVE_LINK = "REMOVE_LINK"
export const SET_SNACKBAR = "SET_SNACKBAR"
export const SET_SNACKBAR_OPEN = "SET_SNACKBAR_OPEN"
export const SET_CREDITS = "SET_CREDITS"
export const SET_HTTP_RESPONSE = "SET_HTTP_RESPONSE"
export const SET_HANDLED_HTTP_RESPONSE = "SET_HANDLED_HTTP_RESPONSE"
export const SET_CONSENT = "SET_CONSENT"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const SET_SHARE = "SET_SHARE"
export const SET_CONFIRM_MODAL = "SET_CONFIRM_MODAL"
export const SET_CONFIRM_MODAL_OPEN = "SET_CONFIRM_MODAL_OPEN"
export const SIGN_OUT = "SIGN_OUT"
