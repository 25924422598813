import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../redux/actions'
import { HTTP_BACKEND_URL } from '../const';

const OneTimeTokenView = props => {

    const { token } = props.match.params
    const [result, setResult] = useState(null)

    const dispatch = useDispatch()
    const user = useSelector(state => state.user) 

    fetch(`${HTTP_BACKEND_URL}/token?authorization=${token}`)
      .then(res => res.json())
      .then(res => {
        if ( !res.error ) {
          dispatch(actions.setUser(res))
        } else {
          setResult(true)
        }
      })
      .catch(err => {
        setResult(true)
      })

    if ( user || result ) {
        return <Redirect to="/" />
    } else {
        return <div style={{ margin: 10 }}>Validating the token and redirecting...</div>
    }
  }

export default OneTimeTokenView;
